var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "ma-0 pa-0",
      attrs: { fluid: "" },
    },
    [
      _c("v-data-table", {
        staticClass: "rounded-0",
        class: _vm.table,
        attrs: {
          headers: _vm.headers,
          height: _vm.tableHeight,
          items: _vm.items,
          "loading-text": "Retrieving Existing Data...Please Wait",
          search: _vm.search,
          loading: _vm.loading,
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "item-key": "id",
          "loader-height": "5",
          "item-class": _vm.itemRowBackground,
          "no-data-text": "No Data to Display",
          "items-per-page": _vm.pageSize,
        },
        on: {
          "update:items": function ($event) {
            _vm.items = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.count_price`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-row",
                    { staticStyle: { height: "60px" }, attrs: { dense: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                right: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container",
                                                style: !_vm.readonly
                                                  ? "cursor: pointer"
                                                  : "cursor: default",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.readonly
                                                      ? _vm.open(item)
                                                      : null
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            !_vm.readonly ? on : null
                                          ),
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-caption ma-0 pa-0",
                                                  },
                                                  [_vm._v("Qty")]
                                                ),
                                                !_vm.readonly
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-0 pa-0",
                                                        attrs: {
                                                          depressed: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.multiplier
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-0 pa-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.multiplier
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingQuantity,
                                callback: function ($$v) {
                                  _vm.editingQuantity = $$v
                                },
                                expression: "editingQuantity",
                              },
                            },
                            [
                              _vm.editItem && _vm.editItem.id === item.id
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "97" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Quantity")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "qty-controls ml-13" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-auto py-0 my-0",
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                                fab: "",
                                                dense: "",
                                                color: "primary",
                                                disabled:
                                                  _vm.editItem.multiplier === 1,
                                              },
                                              on: { click: _vm.decreaseQty },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-minus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-text-field", {
                                            ref: "multiplier",
                                            staticClass: "pt-2 mt-0",
                                            attrs: {
                                              rules: [
                                                (v) => !!v || "Count Required",
                                                (v) =>
                                                  !(v < 0) ||
                                                  "Cannot have negative quantity",
                                              ],
                                              placeholder: "Qty",
                                              min: "1",
                                              "single-line": "",
                                              autofocus: "",
                                              "hide-spin-buttons": "",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.editItem.multiplier,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editItem,
                                                  "multiplier",
                                                  $$v
                                                )
                                              },
                                              expression: "editItem.multiplier",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-auto py-0 my-0",
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                                fab: "",
                                                dense: "",
                                                color: "primary",
                                              },
                                              on: { click: _vm.increaseQty },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.multiplier ||
                                                  _vm.editItem.multiplier < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save("multiplier")
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "accent",
                                                loading: _vm.applyingAll,
                                                disabled:
                                                  _vm.applyingAll ||
                                                  !_vm.editItem.multiplier ||
                                                  _vm.editItem.multiplier < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyAll(
                                                    "multiplier"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Apply All ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-icon",
                        { staticClass: "mx-1 mt-2", attrs: { small: "" } },
                        [_vm._v("mdi-at")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                right: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container",
                                                style: !_vm.readonly
                                                  ? "cursor: pointer"
                                                  : "cursor: default",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.readonly
                                                      ? _vm.open(item)
                                                      : null
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            !_vm.readonly ? on : null
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mx-0 pa-0" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-caption ma-0 pa-0",
                                                  },
                                                  [_vm._v("Sale Price")]
                                                ),
                                                _vm._v(
                                                  " $" +
                                                    _vm._s(
                                                      _vm.formatCurrencyDisplay(
                                                        item.sale_price
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !_vm.readonly
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ml-0 mt-4 pa-0",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingSalePrice,
                                callback: function ($$v) {
                                  _vm.editingSalePrice = $$v
                                },
                                expression: "editingSalePrice",
                              },
                            },
                            [
                              _vm.editItem && _vm.editItem.id === item.id
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "98" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Sale Price")]
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        ref: "sale_price",
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          rules: [
                                            _vm.isValidPrice,
                                            (v) =>
                                              !(v < 0) ||
                                              "Cannot have negative value.",
                                          ],
                                          autofocus: "",
                                          type: "number",
                                          "hide-spin-buttons": "",
                                          prefix: "$",
                                        },
                                        model: {
                                          value: _vm.editItem.sale_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editItem,
                                              "sale_price",
                                              $$v
                                            )
                                          },
                                          expression: "editItem.sale_price",
                                        },
                                      }),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.sale_price ||
                                                  _vm.editItem.sale_price < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save("sale_price")
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "accent",
                                                loading: _vm.applyingAll,
                                                disabled:
                                                  _vm.applyingAll ||
                                                  !_vm.editItem.sale_price ||
                                                  _vm.editItem.sale_price < 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyAll(
                                                    "sale_price"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Apply All ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.cost`,
              fn: function ({ item }) {
                return [
                  item.future_cost == item.cost &&
                  item.future_cost_date <= _vm.selectedAd.ad_end_date
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "primary--text font-weight-bold",
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " $" +
                                            _vm._s(
                                              _vm.formatCurrencyDisplay(
                                                item.cost
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(
                            " This item has a cost change on " +
                              _vm._s(
                                _vm.$dateConfig.formatDate(
                                  item.future_cost_date
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " The new cost of $" +
                              _vm._s(_vm.formatCurrencyDisplay(item.cost)) +
                              " will be used on this ad "
                          ),
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          " $" +
                            _vm._s(_vm.formatCurrencyDisplay(item.cost)) +
                            " "
                        ),
                      ]),
                ]
              },
            },
            {
              key: `item.net_unit_cost`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-row",
                    {
                      staticClass: "ml-0 pa-0",
                      staticStyle: { height: "60px" },
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": false,
                                bottom: "",
                                absolute: "",
                                "nudge-top": "-12px",
                                "nudge-left": "16px",
                                "offset-y": "",
                                "max-width": "300px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "edit-menu-container",
                                                style: !_vm.readonly
                                                  ? "cursor: pointer"
                                                  : "cursor: default",
                                                on: {
                                                  click: function ($event) {
                                                    !_vm.readonly
                                                      ? _vm.open(item)
                                                      : null
                                                  },
                                                },
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            !_vm.readonly ? on : null
                                          ),
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "ml-0 mt-4 pa-0" },
                                              [
                                                _vm._v(
                                                  " $" +
                                                    _vm._s(
                                                      _vm.formatCurrencyDisplay(
                                                        item.net_unit_cost
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !_vm.readonly
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ml-2 mt-4 mb-1 pa-0",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.editingNetUnitCost,
                                callback: function ($$v) {
                                  _vm.editingNetUnitCost = $$v
                                },
                                expression: "editingNetUnitCost",
                              },
                            },
                            [
                              _vm.editItem && _vm.editItem.id === item.id
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pt-2 px-4",
                                      staticStyle: { "z-index": "99" },
                                      attrs: { tile: "", flat: "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "8px" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "subtitle-2" },
                                            [_vm._v("Edit Net Unit Cost")]
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        ref: "net_unit_cost",
                                        staticClass: "pt-0 mt-0",
                                        attrs: {
                                          rules: [
                                            _vm.isValidPrice,
                                            (v) =>
                                              !(v < 0) ||
                                              "Cannot have negative value.",
                                          ],
                                          autofocus: "",
                                          type: "number",
                                          "hide-spin-buttons": "",
                                          prefix: "$",
                                        },
                                        model: {
                                          value: _vm.editItem.net_unit_cost,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editItem,
                                              "net_unit_cost",
                                              $$v
                                            )
                                          },
                                          expression: "editItem.net_unit_cost",
                                        },
                                      }),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "pt-0 px-0" },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: { click: _vm.cancel },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading: _vm.loading,
                                                disabled:
                                                  !_vm.editItem.net_unit_cost ||
                                                  _vm.editItem.net_unit_cost <
                                                    0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save(
                                                    "net_unit_cost"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "accent",
                                                loading: _vm.applyingAll,
                                                disabled:
                                                  _vm.applyingAll ||
                                                  !_vm.editItem.net_unit_cost ||
                                                  _vm.editItem.net_unit_cost <
                                                    0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.applyAll(
                                                    "net_unit_cost"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Apply All ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.pack_size`,
              fn: function ({ item }) {
                return [
                  item.pack_size
                    ? _c("span", [_vm._v(_vm._s(item.pack_size))])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.gross_profit`,
              fn: function ({ item }) {
                return [
                  item.net_unit_cost && item.sale_price && item.multiplier
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formatCurrencyDisplay(_vm.calcNonPromoGP(item))
                          ) + "%"
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ]
              },
            },
            {
              key: `item.related`,
              fn: function ({ item }) {
                return [
                  item[_vm.tenantLinkedProperty]
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "mx-auto",
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewRelatedItems(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mx-auto" },
                                              [_vm._v("mdi-view-list")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Related Items")])]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        disabled: _vm.readonly,
                                        icon: "",
                                        small: "",
                                        color: "red",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItem(item)
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-trash-can")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("Delete")])]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _vm.showRelatedItemsModal
        ? _c("RelatedItemsModal", {
            attrs: {
              item: _vm.currentItem,
              currentItems: _vm.items,
              readOnly: true,
            },
            on: {
              close: _vm.closeRelatedItemsModal,
              cancel: function ($event) {
                _vm.showRelatedItemsModal = false
              },
            },
            model: {
              value: _vm.showRelatedItemsModal,
              callback: function ($$v) {
                _vm.showRelatedItemsModal = $$v
              },
              expression: "showRelatedItemsModal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }